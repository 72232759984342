<script setup>
import { onMounted,reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useMode } from '@/composables/useMode'
import { useAuthentication } from '@/composables/useAuthentication'
import { useLoading } from '@/composables/useLoading'
import { userStore } from '@/stores/userStore'
import { NativeBiometric } from 'capacitor-native-biometric'
import StrikedComponent from '@/components/StrikedComponent.vue'

const router = useRouter()

onMounted(async () => {
    const result = await NativeBiometric.isAvailable()

    if(!result.isAvailable) return

    try {
        await NativeBiometric.verifyIdentity({
            reason: "For easy log in",
        })

        const credentials = await NativeBiometric.getCredentials({
            server: "parasol-health.tthcslabs.com",
        });

        userStore.email = credentials.username

        userStore.password = credentials.password

        doLogin()
    } catch {
        return false
    }
})

const { mode } = useMode()

const { login } = useAuthentication()

const { hideLoading, showLoading } = useLoading()

const loginAlert = reactive({
    status: false,
    header: '',
    subHeader: '',
    message: ''
})

async function doLogin() {
    await showLoading({
        message: "Logging in"
    })

    const response = await login()

    hideLoading()

    if (response === 'invalid') {
        loginAlert.header = 'Alert',
        loginAlert.subHeader = 'Invalid Login'
        loginAlert.message = 'The username or password you entered is invalid.'

        return loginAlert.status = true
    }

    if (response !== 'success') {
        loginAlert.header = 'Alert',
        loginAlert.subHeader = 'Login Error'
        loginAlert.message = 'Please try your request again.'

        return loginAlert.status = true
    }

    router.push({ name: 'home' })
}

</script>

<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button></ion-back-button>
                </ion-buttons>
                <ion-title>Log In</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <ion-alert 
                :is-open="loginAlert.status"
                :header="loginAlert.header"
                :sub-header="loginAlert.subHeader"
                :message="loginAlert.message"
                :buttons="['OK']"
                @didDismiss="loginAlert.status = false"
            ></ion-alert>
            <form @submit.prevent="doLogin">
                <ion-grid>
                    <ion-row class="ion-justify-content-center">
                        <ion-col class="ion-align-self-center" size-md="6" size-lg="5" size-xs="12">
                            <div class="ion-text-center ion-padding">
                                <img v-if="mode === 'dark'" class="logo" src="assets/image/logo-white.png" />
                                <img v-else class="logo" src="assets/image/logo-black.png" />
                            </div>
                            <div>
                                <ion-item lines="full" class="ion-margin">
                                    <ion-label position="floating">
                                        Email
                                    </ion-label>
                                    <ion-input 
                                        v-model.trim="userStore.email"
                                        type="text" 
                                        required>
                                    </ion-input>
                                </ion-item>
                                <ion-item lines="full" class="ion-margin">
                                    <ion-label position="floating">
                                        Password
                                    </ion-label>
                                    <ion-input
                                        v-model="userStore.password"
                                        type="password" 
                                        required
                                    ></ion-input>
                                </ion-item>
                                <div class="ion-padding">
                                    <ion-button color="danger" size="large" type="submit" expand="block">
                                        Sign In
                                    </ion-button>
                                </div>
                                <striked-component text="Need help signing in?" />
                                <div class="ion-padding">
                                    <ion-button color="warning" size="large" type="button" expand="block">
                                        Reset your password
                                    </ion-button>
                                </div>
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </form>
        </ion-content>
    </ion-page>
</template>

<style scoped>
    ion-row {
        height: 90vh;
    }
</style>