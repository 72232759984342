<script setup>
import MenuComponent from '@/components/MenuComponent.vue'
import { onMounted } from 'vue'
import { Capacitor } from '@capacitor/core'

onMounted(() => {
    if (Capacitor.isNativePlatform()) return
    
    const ccaipScript = document.createElement('script')
    ccaipScript.setAttribute('src', 'https://websdk.ujet.co/v2/loader.js')
    document.head.appendChild(ccaipScript)
})
</script>

<template>
    <ion-app>
        <MenuComponent />
        <ion-router-outlet id="main-content" />
    </ion-app>
</template>