<script setup>
import { useRouter } from 'vue-router'
import HeaderMain from '@/components/HeaderMain.vue'
import { play } from 'ionicons/icons'
const router = useRouter()

</script>

<template>
    <ion-page>
        <header-main title="Videos" v-bind:goBack="true" />
        <ion-content :fullscreen="true">
            <ion-card color="light" class="cursor-pointer">
                <ion-card-header>
                    <ion-card-title>
                        CCAI Platform Introduction
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    CCAI Platform's five key features + an overview of the CCAI suite
                </ion-card-content>
                <ion-button fill="clear" @click="router.push('play-video?title=CCAI Platform Introduction')">
                    <ion-icon slot="start" :icon="play"></ion-icon>
                    Play
                </ion-button>
            </ion-card>
        </ion-content>
    </ion-page>
</template>

<style scoped>
    .cursor-pointer {
        cursor: pointer;
    }
</style>