import { ref, onMounted } from 'vue'

export function useMode() {
    const mode = ref(0)

    function getMode() {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            return 'dark'
        }

        return 'light'
    }

    onMounted(() => {
        mode.value = getMode()
    })

    return { mode }
}