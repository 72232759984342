<script setup>
import HeaderMain from '@/components/HeaderMain.vue'
import { chatbubbles, people } from 'ionicons/icons'
import { ujet } from '@/classes/ujet'
import { reactive } from 'vue'

const rejoinAlert = reactive({
    status: false,
    header: 'Alert',
    subHeader: 'Session in Progress',
    message: 'Would you like to re-join the previous session?'
})

async function getHelp() {
    const response = await ujet.checkStatus()

    if (! response) {
        return ujet.start({
            menuKey: "ph-doctor",
        })
    }

    rejoinAlert.status = true
}

function joinExisting() {
    return ujet.start({
        menuKey: "ph-doctor",
    })
}
</script>

<template>
    <ion-page>
        <header-main title="Get Care" />
        <ion-content class="ion-padding">
            <ion-alert 
                :is-open="rejoinAlert.status"
                :header="rejoinAlert.header"
                :sub-header="rejoinAlert.subHeader"
                :message="rejoinAlert.message"
                :buttons="[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => { return true }
                    },
                    {
                        text: 'Yes',
                        role: 'confirm',
                        handler: joinExisting
                    }
                ]"
                @didDismiss="rejoinAlert.status = false"
            ></ion-alert>
            <ion-grid>
                <ion-row class="ion-justify-content-center">
                    <ion-col class="ion-align-self-center" size-md="6" size-lg="5" size-xs="12">
                        <ion-item lines="none" color="none">
                            <ion-label color="danger">
                                <h1>Need Immediate Care?</h1>
                            </ion-label>
                            <ion-icon :icon="people" size="large" slot="start"></ion-icon>
                        </ion-item>
                        <ion-card>
                            <ion-card-header>
                                <ion-card-title>Get help 24/7</ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                                <ion-item lines="none" color="light">
                                    <ion-label class="ion-text-wrap">
                                        Connect 24/7/365 by chat, voice or phone
                                    </ion-label>
                                </ion-item>
                                <ion-item lines="none" color="light">
                                    <ion-label class="ion-text-wrap">
                                        Best for cold & flu, UTI, sore throat, pink eye, and other minor mediacal needs
                                    </ion-label>
                                </ion-item>
                                <ion-item lines="none" color="light">
                                    <ion-label class="ion-text-wrap">
                                        Able to prescribe medication (if necessary)
                                    </ion-label>
                                </ion-item>
                                <ion-button class="ion-margin" color="primary" shape="round" expand="full" @click="getHelp">
                                    <ion-icon slot="start" :icon="chatbubbles"></ion-icon>
                                    <ion-label class="ion-text-wrap"> 
                                        Connect Now
                                    </ion-label>
                                </ion-button>
                            </ion-card-content>
                        </ion-card>
                        <ion-card>
                            <ion-card-header>
                                <ion-card-title>Urgent Care</ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                                <ion-item lines="none" color="light">
                                    <ion-label class="ion-text-wrap">
                                        In person treatment near you
                                    </ion-label>
                                </ion-item>
                                <ion-item lines="none" color="light">
                                    <ion-label class="ion-text-wrap">
                                        Accepts walk-ins and same-day appointments
                                    </ion-label>
                                </ion-item>
                                <ion-item lines="none" color="light">
                                    <ion-label class="ion-text-wrap">
                                        Best for broken bones, deep cuts, severe stomach pain and other minor medical illnesses and injuries
                                    </ion-label>
                                </ion-item>
                                <ion-button class="ion-margin" color="primary" shape="round" expand="full" disabled="true">
                                    <ion-label class="ion-text-wrap"> 
                                        View Locations
                                    </ion-label>
                                </ion-button>
                            </ion-card-content>
                        </ion-card>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<style scoped>
    .icon-spacing {
        padding-right: 1rem;
    }

    .cursor-pointer {
        cursor: pointer;
    }
</style>