<script setup>
import { ref, reactive, computed } from 'vue'
import { useAuthentication } from '@/composables/useAuthentication'
import { useRouter } from 'vue-router'
import { useLoading } from '@/composables/useLoading'

const router = useRouter()

const { register } = useAuthentication()

const { hideLoading, showLoading } = useLoading()

const currentStep = ref(1)

const form = reactive({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    pin: '',
})

const registerAlert = reactive({
    status: false,
    header: '',
    subHeader: '',
    message: ''
})

async function registerCustomer() {
    await showLoading({
        message: "Creating your account..."
    })

    const response = await register(form)

    hideLoading()
    
    if (response !== 'success') {
        registerAlert.header = 'Alert',
        registerAlert.subHeader = 'Registration Failed'

        registerAlert.message = response

        return registerAlert.status = true
    }

    router.push({ name: 'home' })
}

function validate(ev) {
    ev.target.parentElement.classList.remove('ion-valid')
    ev.target.parentElement.classList.remove('ion-invalid')

    if (ev.target.value === '') return;

    switch(ev.target.name) {
        case "email":
            validateEmail(ev.target.value)
                ? ev.target.parentElement.classList.add('ion-valid')
                : ev.target.parentElement.classList.add('ion-invalid')
            break
        case "password":
            validatePassword(ev.target.value)
                ? ev.target.parentElement.classList.add('ion-valid')
                : ev.target.parentElement.classList.add('ion-invalid')
            break
        case "confirm-password":
            validateConfirmPassword(ev.target.value, form.password)
                ? ev.target.parentElement.classList.add('ion-valid')
                : ev.target.parentElement.classList.add('ion-invalid')
            break
        case "first-name":
            validateFirstName(ev.target.value)
                ? ev.target.parentElement.classList.add('ion-valid')
                : ev.target.parentElement.classList.add('ion-invalid')
            break
        case "last-name":
            validateLastName(ev.target.value)
                ? ev.target.parentElement.classList.add('ion-valid')
                : ev.target.parentElement.classList.add('ion-invalid')
            break
        case "phone":
            validatePhone(ev.target.value)
                ? ev.target.parentElement.classList.add('ion-valid')
                : ev.target.parentElement.classList.add('ion-invalid')
            break
        case "pin":
            validatePin(ev.target.value)
                ? ev.target.parentElement.classList.add('ion-valid')
                : ev.target.parentElement.classList.add('ion-invalid')
            break
    }
}

function validateEmail(email) {
    return email.match(/^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
}

function validateFirstName(firstName) {
    const regName = /^[a-zA-Z]+((\s|-)?)([a-zA-Z]?)+(((\s)+)?)$/

    return regName.test(firstName)
}

function validateLastName(lastName) {
    const regName = /^[a-zA-Z]+((\s|-)?)([a-zA-Z]?)+(((\s)+)?)$/

    return regName.test(lastName)
}

function validatePhone(phone) {
    if (typeof phone != "string") return

    if (isNaN(phone)) return

    return phone.length === 10
}

function validatePin(pin) {
    if (typeof pin != "string") return

    if (isNaN(pin)) return

    return pin.length === 4
}

function validatePassword(password) {
    const regPassword = /^[a-zA-Z0-9!@#$%^&*]{8,255}$/

    return regPassword.test(password)
}

function validateConfirmPassword(firstPassword, secondPassword) {
    return firstPassword === secondPassword
}

const isStepOneValid = computed(() => {
    if (
        validateEmail(form.email)
        && validatePassword(form.password) 
        && validateConfirmPassword(form.password, form.confirmPassword)
    ) {
        return true
    }

    return false
})

const isFormValid = computed(() => {
    if (
        validateEmail(form.email)
        && validatePassword(form.password) 
        && validateConfirmPassword(form.password, form.confirmPassword)
        && validateFirstName(form.firstName)
        && validateLastName(form.lastName)
        && validatePhone(form.phone)
        && validatePin(form.pin)
    ) return true

    return false
})

</script>

<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button></ion-back-button>
                </ion-buttons>
                <ion-title>Create your account</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <ion-alert 
                :is-open="registerAlert.status"
                :header="registerAlert.header"
                :sub-header="registerAlert.subHeader"
                :message="registerAlert.message"
                :buttons="['OK']"
                @didDismiss="registerAlert.status = false"
            ></ion-alert>
            <form @submit.prevent="registerCustomer()" autocomplete="on">
                <ion-grid>
                    <ion-row class="ion-justify-content-center">
                        <ion-col class="ion-align-self-center" size-md="6" size-lg="5" size-xs="12">
                            <div v-if="currentStep === 1" class="ion-text-center">
                                <h5>Login Information (Step 1 of 2)</h5>
                                <ion-item class="ion-margin">
                                    <ion-label position="floating">Email</ion-label>
                                    <ion-input 
                                        type="email"
                                        name="email"
                                        autocomplete="email"
                                        v-model.trim="form.email" 
                                        @ionInput="validate"
                                        required
                                    ></ion-input>
                                    <ion-note slot="error">Invalid email</ion-note>
                                </ion-item>
                                <ion-item class="ion-margin">
                                    <ion-label position="floating">Password</ion-label>
                                    <ion-input 
                                        type="password"
                                        name="password"
                                        autocomplete="new-password"
                                        v-model.trim="form.password"
                                        @ionInput="validate"
                                        required
                                    ></ion-input>
                                    <ion-note slot="error">Invalid password</ion-note>
                                </ion-item>
                                <ion-item class="ion-margin">
                                    <ion-label position="floating">Confirm Password</ion-label>
                                    <ion-input 
                                        type="password"
                                        name="confirm-password"
                                        autocomplete="new-password"
                                        v-model.trim="form.confirmPassword"
                                        @ionInput="validate"
                                        required
                                    ></ion-input>
                                    <ion-note slot="error">Passwords do not match</ion-note>
                                </ion-item>
                                <ion-button @click="() => currentStep = 2" class="ion-margin" type="button" :disabled="! isStepOneValid" size="large" color="primary" expand="block">
                                    Next (User Information)
                                </ion-button>
                            </div>
                            <div v-if="currentStep === 2" class="ion-text-center">
                                <h5 class="ion-text-center">User Information (Step 2 of 2)</h5>
                                <ion-item class="ion-margin">
                                    <ion-label position="floating">First Name</ion-label>
                                    <ion-input 
                                        type="text"
                                        name="first-name"
                                        autocapitalize="true"
                                        autocomplete="given-name"
                                        v-model.trim="form.firstName"
                                        @ionInput="validate"
                                        required
                                    ></ion-input>
                                    <ion-note slot="error">Invalid first name</ion-note>
                                </ion-item>
                                <ion-item class="ion-margin">
                                    <ion-label position="floating">Last Name</ion-label>
                                    <ion-input 
                                        type="text"
                                        name="last-name"
                                        autocapitalize="true" 
                                        autocomplete="family-name" 
                                        v-model.trim="form.lastName"
                                        @ionInput="validate"
                                        required
                                    ></ion-input>
                                    <ion-note slot="error">Invalid last name</ion-note>
                                </ion-item>
                                <ion-item class="ion-margin">
                                    <ion-label position="floating">Phone</ion-label>
                                    <ion-input 
                                        type="tel"
                                        name="phone"
                                        autocomplete="tel"
                                        v-model.trim="form.phone" 
                                        @ionInput="validate"
                                        required
                                    ></ion-input>
                                    <ion-note slot="error">Invalid phone</ion-note>
                                </ion-item>
                                <ion-item class="ion-margin">
                                    <ion-label position="floating">Security Pin (4 Digits)</ion-label>
                                    <ion-input 
                                        type="tel"
                                        name="pin"
                                        v-model.trim="form.pin"
                                        @ionInput="validate"
                                        required
                                    ></ion-input>
                                    <ion-note slot="error">Invalid security pin</ion-note>
                                </ion-item>
                                <ion-button class="ion-margin" type="submit" :disabled="! isFormValid" size="large" color="success" expand="block">
                                    Register
                                </ion-button>
                                <ion-button @click="() => currentStep = 1" class="ion-margin" type="button" size="large" color="warning" expand="block">
                                    Go Back (Login Information)
                                </ion-button>

                                
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </form>
        </ion-content>
    </ion-page>
</template>

<style scoped>
     .strike {
        display: block;
        text-align: center;
        overflow: hidden;
        white-space: nowrap; 
    }

    .strike > span {
        position: relative;
        display: inline-block;
    }

    .strike > span:before,
    .strike > span:after {
        content: "";
        position: absolute;
        top: 50%;
        width: 9999px;
        height: 1px;
        background: lightgrey
    }

    .strike > span:before {
        right: 100%;
        margin-right: 15px;
    }

    .strike > span:after {
        left: 100%;
        margin-left: 15px;
    }

    .text-center {
        text-align: center;
    }
</style>