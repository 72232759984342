import { Preferences } from '@capacitor/preferences'
import { userStore } from '@/stores/userStore'

export function usePreferences() {

    async function getEmailFromLocalStorage() {
        const email = await Preferences.get({ key: 'email' })

        userStore.email = email.value

        return email
    }

    async function getTokenFromLocalStorage() {
        const token = await Preferences.get({ key: 'token' })

        userStore.token = token.value

        return token
    }
    
    async function removeEmail() {
        await Preferences.remove({ key: 'email' })
    }

    async function removeToken() {
        await Preferences.remove({ key: 'token' })
    }

    async function setEmail(value) {
        await Preferences.set({
            key: 'email',
            value: value,
        })

        userStore.email = value
    }

    async function setToken(value) {
        await Preferences.set({
            key: 'token',
            value: value,
        })

        userStore.token = value
    }

    return {
        getEmailFromLocalStorage,
        getTokenFromLocalStorage,
        removeEmail,
        removeToken,
        setEmail,
        setToken,
    }
}