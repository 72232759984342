<script setup>
import { useRouter } from 'vue-router'
import { defineProps } from 'vue'
import { documentText, heart, telescope } from 'ionicons/icons'

const router = useRouter()

defineProps({
    page: String,
    icon: String,
    title: String,
    subtitle: String,
})
</script>

<template>
    <ion-card @click="router.push(page)" color="light" class="cursor-pointer">
        <ion-item color="light">
            <ion-icon v-if="icon === 'heart'" slot="start" :icon="heart" size="large" color="primary"></ion-icon>
            <ion-icon v-else-if="icon === 'telescope'" slot="start" :icon="telescope" size="large" color="primary"></ion-icon>
            <ion-icon v-else slot="start" :icon="documentText" size="large" color="primary"></ion-icon>
            <div>
                <p class="card-title">{{ title }}</p>
                <ion-card-subtitle>{{ subtitle }}</ion-card-subtitle>
                <p />
            </div>
        </ion-item>
    </ion-card>
</template>

<style scoped>
    .cursor-pointer {
        cursor: pointer;
    }
</style>