<script setup>
import { modalController } from '@ionic/vue'
import { defineProps, ref } from 'vue'
import { useCustomerInfo } from '@/composables/useCustomerInfo'
import { useLoading } from '@/composables/useLoading'
import { useAlert } from '@/composables/useAlert'

const props = defineProps({
    fieldDescription: String,
    fieldValue: String,
    fieldKey: String,
    confirm: Boolean
})

const { presentAlert } = useAlert()

const { updateCustomer } = useCustomerInfo()

const { hideLoading, showLoading } = useLoading()

const newValue = ref(props.fieldValue)

const newConfirmValue = ref('')

function cancel() {
    return modalController.dismiss(null, 'cancel');
}

async function confirm() {
    if (! isDataValid()) {
        presentAlert({
            header: 'Alert',
            subHeader: 'Invalid Entry',
            message: 'The data provided is invalid',
        })

        return
    }

    await showLoading({
        message: "Saving changes..."
    })

    const response = await updateCustomer({
        [props.fieldKey]: newValue.value
    }) 

    hideLoading()

    if (response !== 'success') {
        return
    }

    return modalController.dismiss({
        newValue: newValue.value,
        newConfirmValue: newConfirmValue.value
    }, 'confirm')
}

function isDataValid() {
    let dataIsValidated = false

    switch(props.fieldKey) {
        case "email":
            if (validateEmail(newValue.value)) {
                dataIsValidated = true
            }
            break
        case "first_name":
            if (validateFirstName(newValue.value)) {
                dataIsValidated = true
            }
            break
        case "last_name":
            if (validateLastName(newValue.value)) {
                dataIsValidated = true
            }
            break
        case "mobile_phone":
            if (validatePhone(newValue.value)) {
                dataIsValidated = true
            }
            break
        case "pin":
            if (validatePin(newValue.value)) {
                dataIsValidated = true
            }
            break
        case "password":
            if (! validatePassword(newValue.value)) {
                break
            }

            if (validateConfirmPassword(newValue.value, newConfirmValue.value))
            {
                dataIsValidated = true
            }
            break
    }

    return dataIsValidated
}

function validateEmail(email) {
    return email.match(/^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
}

function validateFirstName(firstName) {
    const regName = /^[a-zA-Z]+((\s|-)?)([a-zA-Z]?)+(((\s)+)?)$/

    return regName.test(firstName)
}

function validateLastName(lastName) {
    const regName = /^[a-zA-Z]+((\s|-)?)([a-zA-Z]?)+(((\s)+)?)$/

    return regName.test(lastName)
}

function validatePhone(phone) {
    if (typeof phone != "string") return

    if (isNaN(phone)) return

    return phone.length === 10
}

function validatePin(pin) {
    if (typeof pin != "string") return

    if (isNaN(pin)) return

    return pin.length === 4
}

function validatePassword(password) {
    const regPassword = /^[a-zA-Z0-9!@#$%^&*]{8,255}$/

    return regPassword.test(password)
}

function validateConfirmPassword(firstPassword, secondPassword) {
    return firstPassword === secondPassword
}

</script>

<template>
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button color="medium" @click="cancel">Cancel</ion-button>
            </ion-buttons>
            <ion-title>Edit Setting</ion-title>
            <ion-buttons slot="end">
                <ion-button @click="confirm">Confirm</ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content>
        <ion-item class="ion-margin">
            <ion-label position="stacked">New {{ props.fieldDescription }}</ion-label>
            <ion-input v-if="newConfirmValue" type="password" v-model.trim="newValue"></ion-input>
            <ion-input v-else type="text" v-model.trim="newValue"></ion-input>
        </ion-item>
        <ion-item v-if="props.confirm" class="ion-margin">
            <ion-label position="stacked">Confirm New {{ props.fieldDescription }}</ion-label>
            <ion-input type="password" v-model.trim="newConfirmValue"></ion-input>
        </ion-item>
    </ion-content>
</template>