import { Ccaip } from 'capacitor-plugin-ccaip'
import { userStore } from '@/stores/userStore'
// import { useCrm } from '@/composables/useCrm'

export default class Ujet {
    constructor() {
        this.companyId = process.env.VUE_APP_CCAIP_COMPANY_ID
        
        this.subdomain = process.env.VUE_APP_CCAIP_SUBDOMAIN

        this.domain = process.env.VUE_APP_CCAIP_DOMAIN

        this.signingUrl = `${process.env.VUE_APP_PARASOL_BACKEND_URL}/api/admin/ccaip/sign`

        this.isInitialized = false
    }

    async init() {
        if (this.isInitialized) return

        Ccaip.initialize({
            companyId: this.companyId,
            subdomain: this.subdomain,
            domain: this.domain,
            signingUrl: this.signingUrl,
            customData: {
                customerId: {
                    label: 'Customer ID',
                    value: userStore.id
                },
            }
        })

        this.isInitialized = true
    }

    async checkStatus() {
        this.init()

        const response = await Ccaip.isActive()

        return response.status
    }

    async start(data) {
        this.init()

        /*
        if (! data.ticketId) {
            const { caseCreate } = useCrm()

            const newTicket = await caseCreate(data.subject, data.description)

            if (newTicket !== 'error') {
                data.ticketId = newTicket

                console.log("New ticket:", data.ticketId)
            }
        }
        */

        Ccaip.open({
            payload: {
                identifier: userStore.email,
                email: userStore.email
            },
            menuKey: data.menuKey,
            ticketId: data.ticketId
        })
    }

    end() {
        this.init()

        Ccaip.end()
    }
}