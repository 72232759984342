import { userStore } from '@/stores/userStore'
import { usePreferences } from '@/composables/usePreferences'
import { NativeBiometric } from 'capacitor-native-biometric'
import api from '@/shared/api'

export function useAuthentication() {

    const { setToken, setEmail, removeToken } = usePreferences()

    async function login() {
        try {
            const response = await api.post('/api/customer/auth/login', {
                email: userStore.email,
                password: userStore.password
            })

            if (! response.data.status === 'success') {
                return 'invalid'
            }

            NativeBiometric.setCredentials({
                username: userStore.email,
                password: userStore.password,
                server: "parasol-health.tthcslabs.com",
            })

            updateUserData(response.data)

            return 'success'
        } catch (e) {
            return 'error'
        }
    }

    async function logout() {
        userStore.isAuthenticated = false

        removeToken()

        try {
            const response = await api.post('/api/customer/auth/logout', {}, {
                headers: {
                    Authorization: `Bearer ${userStore.token}`
                }
            })

            if (! response.data.status === 'success') {
                return 'error'
            }

            return 'success'
        } catch {
            return 'error'
        }
    }

    async function register(data) {
        try {
            const response = await api.post('/api/customer/auth/register', {
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                mobile_phone: data.phone,
                pin: data.pin,
                password: data.password,
                password_confirmation: data.password,
            })

            updateUserData(response.data)

            return 'success'
        } catch (e) {
            if (! e.response?.data?.message) {
                return 'An unknown error occurred.'
            }

            return e.response.data.message
        }
    }

    async function refreshCustomerToken() {
        try {
            const response = await api.post('/api/customer/auth/refresh', {}, {
                headers: {
                    Authorization: `Bearer ${userStore.token}`
                }
            })

            updateUserData(response.data)

            return 'success'
        } catch {
            return 'error'
        }
    }

    function updateUserData(data) {
        userStore.isAuthenticated = true
        userStore.password = ''

        userStore.id = data.customer.id
        userStore.firstName = data.customer.first_name
        userStore.lastName = data.customer.last_name
        userStore.email = data.customer.email
        userStore.accountNumber = data.customer.account_number
        userStore.phone = data.customer.mobile_phone

        setEmail(data.customer.email)
        setToken(data.token)
    }

    return { 
        login, 
        logout,
        refreshCustomerToken,
        register,
    }
}