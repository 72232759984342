<script setup>
import { useRouter } from 'vue-router'
import HeaderMain from '@/components/HeaderMain.vue'
import { ref, onMounted } from 'vue'

const router = useRouter()

const filename = ref(null)
const fileUrl = ref(null)

onMounted(() => {
    filename.value = router.currentRoute.value.query.title
    fileUrl.value = `https://storage.googleapis.com/parasol-files/${filename.value}.mp4`
})

</script>
<template>
    <ion-page>
        <header-main :title="filename" v-bind:goBack="true" />
        <ion-content :fullscreen="true">
            <div class="video-wrapper">
                <video controls class="video-player">
                    <source :src="fileUrl" type="video/mp4">
                </video>
            </div>
        </ion-content>
    </ion-page>
</template>

<style scoped>
.video-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.video-player {
    max-width: 100vw;
    max-height: 100vh;
}

</style>