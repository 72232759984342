<script setup>
import { useRouter } from 'vue-router'
import { menuController } from '@ionic/vue'
import { logOut, videocamOutline, homeOutline, medkitOutline, documentTextOutline, heartOutline, telescopeOutline } from 'ionicons/icons'
import { useAuthentication } from '@/composables/useAuthentication'
import { useLoading } from '@/composables/useLoading'


const router = useRouter()

const { logout } = useAuthentication()

const { hideLoading, showLoading } = useLoading()

async function doLogout() {
    await showLoading({
        message: "Signing out..."
    })

    await logout()

    hideLoading()

    menuController.close('app-menu')

    router.push({ name: 'launch' })
}

function menuNavigation(value) {
    menuController.close('app-menu')

    router.push(value)
}
</script>

<template>
    <ion-menu menu-id="app-menu" content-id="main-content" type="push" side="end">
        <ion-header>
            <ion-toolbar color="tertiary">
                <ion-title>Menu</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-list>
                <ion-item>
                    <ion-button fill="clear" size="default" color="dark" class="right-separator" @click="menuNavigation('profile')">Profile</ion-button>
                    <ion-button fill="clear" size="default" color="dark" class="left-separator" @click="menuNavigation('support')">Support</ion-button>
                </ion-item>
                <ion-item class="cursor-pointer" @click="menuNavigation('home')">
                    <ion-icon :icon="homeOutline" class="icon-padding"></ion-icon>
                    <ion-label>Home</ion-label>
                </ion-item>
                <ion-item class="cursor-pointer" @click="menuNavigation('get-care')">
                    <ion-icon :icon="medkitOutline" class="icon-padding"></ion-icon>
                    <ion-label>Get Care</ion-label>
                </ion-item>
                <ion-item class="cursor-pointer" @click="menuNavigation('claims')">
                    <ion-icon :icon="documentTextOutline" class="icon-padding"></ion-icon>
                    <ion-label>Claims</ion-label>
                </ion-item>
                <ion-item class="cursor-pointer" @click="menuNavigation('coverages')">
                    <ion-icon :icon="telescopeOutline" class="icon-padding"></ion-icon>
                    <ion-label>Coverages</ion-label>
                </ion-item>
                <ion-item class="cursor-pointer" @click="menuNavigation('my-wellness')">
                    <ion-icon :icon="heartOutline" class="icon-padding"></ion-icon>
                    <ion-label>My Wellness</ion-label>
                </ion-item>
                <ion-item class="cursor-pointer" @click="menuNavigation('videos')">
                    <ion-icon :icon="videocamOutline" class="icon-padding"></ion-icon>
                    <ion-label>Videos</ion-label>
                </ion-item>
                <ion-item lines="none"></ion-item>
            </ion-list>
            <ion-button @click="doLogout" color="danger" expand="full">
                <ion-icon slot="start" :icon="logOut"></ion-icon>
                Logout
            </ion-button>
        </ion-content>
    </ion-menu>
</template>

<style scoped>
    ion-menu::part(container) {
        border-radius: 0 20px 20px 0;
        box-shadow: 4px 0px 16px rgba(255, 0, 255, 0.18);
    } 

    .cursor-pointer {
        cursor: pointer;
    }

    .icon-padding {
        padding-right: 1rem;
    }

    .right-separator {
        padding-right: 1rem;
        border-right: 1px solid lightgray;
    }

    .left-separator {
        padding-left: 1rem;
    }
</style>