import { loadingController } from '@ionic/vue'

export function useLoading() {
    let loading

    function hideLoading() {
        loading.dismiss()
    }

    async function showLoading(config) {
        loading = await loadingController.create({
            message: config.message || 'Loading...',
            spinner: 'circles'
        })

        loading.present()
    }

    return {
        hideLoading,
        showLoading
    }
}