import { createRouter, createWebHistory } from '@ionic/vue-router'
import { userStore } from '@/stores/userStore'
import ForgotPassword from '@/views/ForgotPassword.vue'
import NavPage from '@/views/NavPage.vue'
import HomePage from '@/views/HomePage.vue'
import LaunchPage from '@/views/LaunchPage.vue'
import LoginPage from '@/views/LoginPage.vue'
import RegisterPage from '@/views/RegisterPage.vue'
import GetCare from '@/views/GetCare.vue'
import SupportPage from '@/views/SupportPage.vue'
import ProfilePage from '@/views/ProfilePage.vue'
import ClaimsPage from '@/views/ClaimsPage.vue'
import MyWellness from '@/views/MyWellness.vue'
import CoveragesPage from '@/views/CoveragesPage.vue'
import VideosPage from '@/views/VideosPage.vue'
import PlayVideo from '@/views/PlayVideo.vue'

const routes = [
  {
    path: '/',
    redirect: '/nav/home'
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword
  },
  {
    path: '/nav/',
    beforeEnter: guardAuthenticated,    
    component: NavPage,
    children: [
        {
            path: '',
            redirect: '/nav/home'
        },
        {
            path: 'claims',
            name: 'claims',
            component: ClaimsPage
        },
        {
            path: 'coverages',
            name: 'coverages',
            component: CoveragesPage
        },
        {
            path: 'home',
            name: 'home',
            component: HomePage
        },
        {
            path: 'get-care',
            name: 'getCare',
            component: GetCare
        },
        {
            path: 'support',
            name: 'support',
            component: SupportPage
        },
        {
            path: 'my-wellness',
            name: 'myWellness',
            component: MyWellness
        },
        {
            path: 'profile',
            name: 'profile',
            component: ProfilePage
        },
        {
            path: 'videos',
            name: 'videos',
            component: VideosPage
        },
        {
            path: 'play-video',
            name: 'playVideo',
            component: PlayVideo
        }
    ]
  },
  {
    path: '/launch',
    name: 'launch',
    component: LaunchPage
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterPage
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/nav/home'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function guardAuthenticated(to, from, next) {
    if (userStore.isAuthenticated) {
        next()
    } else {
        next('/launch')
    }
}

export default router
