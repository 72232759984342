<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button></ion-back-button>
                </ion-buttons>
                <ion-title>Reset Your Password</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <form>
                <ion-item lines="full">
                    <ion-label position="floating">Email</ion-label>
                    <ion-input type="email" required></ion-input>
                </ion-item>
                <ion-item lines="none"></ion-item>
                <ion-row>
                    <ion-col>
                        <ion-button type="submit" color="danger" expand="block">Send</ion-button>
                    </ion-col>
                </ion-row>
                <small>
                    Please provide the email address that you used when you signed up for your Parasol Health account.
                </small>
            </form>
        </ion-content>
    </ion-page>
</template>