import { alertController } from '@ionic/vue'

export function useAlert() {
    async function presentAlert(config) {
        const alert = await alertController.create({
            header: config.header,
            subHeader: config.subHeader,
            message: config.message,
            buttons: ['OK'],
        })
    
        await alert.present();
    }

    return {
        presentAlert
    }
}