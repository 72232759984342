<script setup>
import HeaderMain from '@/components/HeaderMain.vue'
import ProfileTableRow from '@/components/ProfileTableRow.vue';
import ProfileSettingUpdate from '@/components/ProfileSettingUpdate.vue';
import { useRouter } from 'vue-router'
import { userStore } from '@/stores/userStore'
import { modalController } from '@ionic/vue'
import { useCustomerInfo } from '@/composables/useCustomerInfo'
import { useLoading } from '@/composables/useLoading'
import { alertController } from '@ionic/vue';

const router = useRouter()

const { deleteCustomer } = useCustomerInfo()

const { hideLoading, showLoading } = useLoading()

async function doDelete() {
    await showLoading({
        message: "Deleting account..."
    })

    const response = await deleteCustomer()

    hideLoading()

    if (response !== 'success') {
        return
    }

    router.push({ name: 'launch' })
}

async function confirmDelete() {
    const alert = await alertController.create({
        header: 'Confirm Action',
        subHeader: 'Are you sure you wish to delete your account?',
        message: ' This action cannot be undone.',
        buttons: [
            {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                    return false
                }
            }, 
            {
                text: 'OK',
                role: 'confirm',
                handler: () => {
                    doDelete()
                }
            }
        ]
    })

    await alert.present()
}

async function updateUser(setting, key, value, confirm) {
    const modal = await modalController.create({
        component: ProfileSettingUpdate,
        componentProps: {
            fieldDescription: setting,
            fieldKey: key,
            fieldValue: value,
            confirm: confirm
        }
    })

    modal.present()
}

</script>
<template>
    <ion-page>
        <header-main title="Profile" />
        <ion-content class="ion-padding">
            <ion-grid>
                <ion-row class="ion-justify-content-center">
                    <ion-col class="ion-align-self-center" size-lg="10" size-xl="9" size-xs="12">
                        <ion-grid>
                            <ion-row>
                                <profile-table-row
                                    title="Member ID"
                                    :value="userStore.accountNumber"
                                />
                                <profile-table-row
                                    title="First Name"
                                    :value="userStore.firstName"
                                    :canEdit="true"
                                    @update-setting="updateUser('First Name', 'first_name', userStore.firstName)"
                                />
                                <profile-table-row
                                    title="Last Name"
                                    :value="userStore.lastName"
                                    :canEdit="true"
                                    @update-setting="updateUser('Last Name', 'last_name', userStore.lastName)"
                                />
                                <profile-table-row
                                    title="Email"
                                    :value="userStore.email"
                                    :canEdit="true"
                                    @update-setting="updateUser('Email', 'email', userStore.email)"
                                />
                                <profile-table-row
                                    title="Phone"
                                    :value="userStore.phone"
                                    :canEdit="true"
                                    @update-setting="updateUser('Phone', 'mobile_phone', userStore.phone)"
                                />
                                <profile-table-row
                                    title="Password"
                                    value="********"
                                    :canEdit="true"
                                    @update-setting="updateUser('Password', 'password', '', true)"
                                />
                                <profile-table-row
                                    title="Security Pin (4 Digits)"
                                    value="****"
                                    :canEdit="true"
                                    @update-setting="updateUser('Security Pin', 'pin', '')"
                                />
                            </ion-row>
                            <ion-row class="ion-justify-content-center">
                                <ion-col size-xs="12" size-md="10" size-lg="8" size-xl="6">
                                    <ion-button @click="confirmDelete()" class="ion-margin" size="large" expand="block" color="danger">
                                        Delete My Acount
                                    </ion-button>
                                </ion-col>
                            </ion-row> 
                        </ion-grid>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>