import { reactive } from 'vue'

export const userStore = reactive({
    isAuthenticated: false,
    password: '',
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    accountNumber: '',
    phone: '',
    token: ''
})
