<script setup>
import { onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useMode } from '@/composables/useMode'
import { usePreferences } from '@/composables/usePreferences'
import { useAuthentication } from '@/composables/useAuthentication'
import StrikedComponent from '@/components/StrikedComponent.vue'

const router = useRouter()

const { getEmailFromLocalStorage, getTokenFromLocalStorage } = usePreferences()

const { refreshCustomerToken } = useAuthentication()

const { mode } = useMode()

onBeforeMount(async () => {
    const email = await getEmailFromLocalStorage()

    if (! email.value) return 

    const token = await getTokenFromLocalStorage()

    if (! token.value) {
        return router.push('login')
    }
    
    const response = await refreshCustomerToken()

    if (! response === 'success') {
        return router.push('login')
    }

    router.push('home')
})

</script>

<template>
    <ion-page>
        <ion-content class="ion-padding">
            <ion-grid>
                <ion-row class="ion-justify-content-center">
                    <ion-col class="ion-align-self-center" size-md="6" size-lg="5" size-xs="12">
                        <div class="ion-text-center ion-padding">
                            <img v-if="mode === 'dark'" class="logo" src="assets/image/logo-white.png" />
                            <img v-else class="logo" src="assets/image/logo-black.png" />
                        </div>
                        <div class="ion-padding">
                            <ion-button router-link="/register" color="tertiary" size="large" type="button" expand="block">
                                Register
                            </ion-button>
                        </div>
                        <striked-component text="or"/>
                        <div class="ion-padding ion-text-center">
                            Already a user?
                        </div>
                        <div class="ion-padding">
                            <ion-button router-link="/login" color="danger" size="large" type="button" expand="block">
                                Sign In
                            </ion-button>
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<style scoped>
    ion-row {
        height: 90vh;
    }
</style>
