import axios from 'axios'

export default axios.create({
    baseURL: process.env.VUE_APP_PARASOL_BACKEND_URL,
    timeout: 10000,
    headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    }
})