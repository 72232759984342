<script setup>
import HeaderMain from '@/components/HeaderMain.vue'
import { onMounted, ref } from 'vue';
import { useCrm } from '@/composables/useCrm'
import { caseStore } from '@/stores/caseStore'
import { ujet } from '@/classes/ujet'
import { helpCircle, trash } from 'ionicons/icons';
import { useLoading } from '@/composables/useLoading'
import StrikedComponent from '@/components/StrikedComponent.vue'

const { hideLoading, showLoading } = useLoading()

const { caseSearch, caseUpdate } = useCrm()

const caseList = ref(null)

const caseHeaderText = ref('')

onMounted(() => {
    refreshCases()
})

async function refreshCases() {
    await showLoading({
        message: "Checking for open cases..."
    })

    caseHeaderText.value = 'Loading Cases...'

    await caseSearch()

    hideLoading()

    caseHeaderText.value = 'No Open Cases'
}

function getHelp(caseNumber) {
    caseList.value.$el.closeSlidingItems()
    
    if (! caseNumber) {
        const today = new Date()
        let hours = today.getHours();
        let minutes = today.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;

        return ujet.start({
            menuKey: "ph",
            subject: `Customer Support Session - ${hours}:${minutes} ${ampm}`,
            description: "Customer requested a new support session."
        })
    }

    return ujet.start({
        menuKey: "ph",
        ticketId: caseNumber
    })
}

async function closeCase(id, index) {
    await showLoading({
        message: "Closing the case..."
    })

    caseList.value.$el.closeSlidingItems()

    const response = await caseUpdate(id, {
        status: "closed"
    })

    hideLoading()

    if (! response) {
        return
    }

    caseStore.splice(index, 1)
}

function formatDate(datetime) {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(datetime);
    return formattedDate.toLocaleDateString("en-US", options)
}

</script>

<template>
    <ion-page>
        <header-main title="Customer Support" />
        <ion-content>
            <ion-grid>
                <ion-row class="ion-justify-content-center">
                    <ion-col class="ion-align-self-center" size-md="6" size-lg="5" size-xs="12">
                        <ion-list ref="caseList">
                            <ion-list-header lines="inset">
                                <ion-label v-if="caseStore.length">Open Cases</ion-label>
                                <ion-label v-else>{{ caseHeaderText }}</ion-label>
                                <ion-button @click="refreshCases()">
                                    Refresh
                                </ion-button>
                            </ion-list-header>
                            <ion-item-sliding v-for="(crmCase, index) in caseStore" :key="crmCase.id" color="none">
                                <ion-item>
                                    <ion-label class="ion-text-wrap">
                                        {{ crmCase.subject}}
                                        <br/>
                                        <span class="sub-item">{{ formatDate(crmCase.created_date) }}</span>
                                    </ion-label>
                                    
                                </ion-item>
                                <ion-item-options side="end">
                                    <ion-item-option @click="getHelp(crmCase.id)">
                                        <ion-icon slot="icon-only" :icon="helpCircle"></ion-icon>
                                    </ion-item-option>
                                    <ion-item-option @click="closeCase(crmCase.id, index)" color="danger">
                                        <ion-icon slot="icon-only" :icon="trash"></ion-icon>
                                    </ion-item-option>
                                </ion-item-options>
                            </ion-item-sliding>  
                        </ion-list>
                        <striked-component text="Something New We Can Help With?" class="ion-padding"/>
                        <ion-button expand="full" size="large" color="danger" class="ion-margin" @click="getHelp()">
                            Start a support session
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<style scoped>
.sub-item {
    font-size: 14px;
}
</style>