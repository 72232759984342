import { createApp } from 'vue'
import './App.css'
import App from './App.vue'
import router from './router'

import { 
    IonicVue, 
    IonAccordion,
    IonAccordionGroup,
    IonAlert,
    IonApp,
    IonButton,
    IonButtons,
    IonBackButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonHeader, 
    IonIcon,
    IonInput,
    IonItem,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonLabel,
    IonLoading,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuButton,
    IonNote,
    IonPage,
    IonRouterOutlet, 
    IonRow,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonTitle,
    IonToolbar, 
} from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'

const app = createApp(App)
    .use(IonicVue)
    .use(router)

app.component('ion-accordion', IonAccordion)
app.component('ion-accordion-group', IonAccordionGroup)
app.component('ion-alert', IonAlert)
app.component('ion-app', IonApp)
app.component('ion-button', IonButton)
app.component('ion-buttons', IonButtons)
app.component('ion-back-button', IonBackButton)
app.component('ion-card', IonCard)
app.component('ion-card-content', IonCardContent)
app.component('ion-card-header', IonCardHeader)
app.component('ion-card-subtitle', IonCardSubtitle)
app.component('ion-card-title', IonCardTitle)
app.component('ion-col', IonCol)
app.component('ion-content', IonContent)
app.component('ion-fab', IonFab)
app.component('ion-fab-button', IonFabButton)
app.component('ion-grid', IonGrid)
app.component('ion-header', IonHeader)
app.component('ion-icon', IonIcon)
app.component('ion-item', IonItem)
app.component('ion-item-sliding', IonItemSliding)
app.component('ion-item-option', IonItemOption)
app.component('ion-item-options', IonItemOptions)
app.component('ion-input', IonInput)
app.component('ion-label', IonLabel)
app.component('ion-list', IonList)
app.component('ion-list-header', IonListHeader)
app.component('ion-loading', IonLoading)
app.component('ion-menu', IonMenu)
app.component('ion-menu-button', IonMenuButton)
app.component('ion-note', IonNote)
app.component('ion-page', IonPage)
app.component('ion-router-outlet', IonRouterOutlet)
app.component('ion-row', IonRow)
app.component('ion-select', IonSelect)
app.component('ion-select-option', IonSelectOption)
app.component('ion-spinner', IonSpinner)
app.component('ion-tab-bar', IonTabBar)
app.component('ion-tab-button', IonTabButton)
app.component('ion-tabs', IonTabs)
app.component('ion-title', IonTitle)
app.component('ion-toolbar', IonToolbar)
 
router.isReady().then(() => {
    app.mount('#app');
});