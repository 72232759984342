import { userStore } from '@/stores/userStore'
import { caseStore } from '@/stores/caseStore'
import api from '@/shared/api'

export function useCrm() {
    async function caseCreate(subject, description) {
        try {
            const response = await api.post('/api/customer/crm/case', {
                subject: subject,
                description: description
            }, {
                headers: {
                    Authorization: `Bearer ${userStore.token}`
                }
            })

            return response.data.crm_case.id
        } catch (e) {
            return 'error'
        }
    }

    async function caseSearch() {
        try {
            const response = await api.post('/api/customer/crm/case/search', {}, {
                headers: {
                    Authorization: `Bearer ${userStore.token}`
                }
            })

            caseStore.splice(0)

            response.data.crm_cases.forEach((crm_case) => {
                caseStore.push(crm_case)
            })

            return 'success'
        } catch (e) {
            return 'error'
        }
    }
    async function caseUpdate(id, data) {
        try {
            await api.patch(`/api/customer/crm/case/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${userStore.token}`
                }
            })

            return true
        } catch (e) {
            return false
        }
    }

    return { 
        caseCreate,
        caseSearch,
        caseUpdate
    }
}