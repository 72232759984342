import { userStore } from '@/stores/userStore'
import { usePreferences } from '@/composables/usePreferences'
import { NativeBiometric } from 'capacitor-native-biometric'
import api from '@/shared/api'

export function useCustomerInfo() {

    const { setEmail, removeToken, removeEmail } = usePreferences()

    async function deleteCustomer() {
        try {
            await api.delete(`/api/customer/info/${userStore.id}`, {
                headers: {
                    Authorization: `Bearer ${userStore.token}`
                }
            })

            NativeBiometric.deleteCredentials({
                server: "parasol-health.tthcslabs.com",
            })

            removeEmail()

            removeToken()

            return 'success'
        } catch (e) {
            return 'error'
        }
    }

    async function updateCustomer($data) {
        try {
            const response = await api.patch(`/api/customer/info/${userStore.id}`, $data, {
                headers: {
                    Authorization: `Bearer ${userStore.token}`
                }
            })

            updateUserData(response.data.data)

            return 'success'
        } catch (e) {
            return 'error'
        }
    }

    function updateUserData(data) {
        userStore.isAuthenticated = true
        userStore.password = ''

        userStore.id = data.id
        userStore.firstName = data.first_name
        userStore.lastName = data.last_name
        userStore.email = data.email
        userStore.accountNumber = data.account_number
        userStore.phone = data.mobile_phone

        setEmail(data.email)
    }

    return { 
        deleteCustomer,
        updateCustomer, 
    }
}